<script setup>
import { ref, onMounted } from "vue";
const isOpen = ref(false);
const id = ref(null);
const _uid = ref(null);

function toggleAccordion() {
  isOpen.value = !isOpen.value;
}

onMounted(() => {
  id.value = _uid.value;
});
</script>

<template>
  <div class="w-100">
    <button
      @click="toggleAccordion()"
      class="d-flex w-100 px-3 card card-header accordion-head"
      :aria-expanded="isOpen"
      :aria-controls="`collapse${id}`"
    >
      <slot name="title" />
    </button>

    <div
      :class="{ show: isOpen }"
      :id="`collapse${id}`"
      class="collapse card card-body accordion-body animate__animated animate__fadeIn"
    >
      <slot name="content" />
    </div>
  </div>
</template>
