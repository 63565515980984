<script setup>
import { ref } from "vue";
import { useElementVisibility } from "@vueuse/core";
import clientConfig from "/src/config/client";
import "bootstrap";
import PDFElement from "./PDFElement.vue";
import TeamMember from "./TeamMember.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});

const filterDocs = ref(
  clientConfig.settings.disableDocsFilter == true ? "docs" : "info"
);
const displayDocsFilter = ref(filterDocs.value == "docs" ? false : true);
const showModal = ref(false);
const pdfs = ref(clientConfig.content.pdfs);
const teamMembers = ref(clientConfig.content.teamMembers);

const home = ref(null);
const scrolledToHome = useElementVisibility(home);
const docs = ref(null);
const scrolledToDocs = useElementVisibility(docs);
const superscore = ref(null);
const scrolledToSuperscore = useElementVisibility(superscore);
const calculators = ref(null);
const scrolledToCalculators = useElementVisibility(calculators);
const contacts = ref(null);
const scrolledToContacts = useElementVisibility(contacts);
</script>

<template>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top">
    <div class="container d-flex justify-content-between">
      <a href="/" class="logo mr-auto" aria-label="Homepage"></a>
      <nav class="nav-menu">
        <ul>
          <li>
            <router-link to="/#hero" :class="scrolledToHome ? 'active' : ''"
              >Home</router-link
            >
          </li>
          <li>
            <router-link to="/#docs" :class="scrolledToDocs ? 'active' : ''"
              >Documents</router-link
            >
          </li>
          <li v-if="clientConfig.superscore == true">
            <router-link
              to="/#superscore"
              :class="scrolledToSuperscore ? 'active' : ''"
              >Superscore</router-link
            >
          </li>
          <li>
            <router-link
              to="/#calculators"
              :class="scrolledToCalculators ? 'active' : ''"
              >Calculators</router-link
            >
          </li>
          <li>
            <router-link
              to="/#footer"
              :class="scrolledToContacts ? 'active' : ''"
              >Contact</router-link
            >
          </li>
          <li>
            <router-link to="/faq">FAQs</router-link>
          </li>
        </ul>
      </nav>
      <!-- .nav-menu -->
    </div>
  </header>
  <!-- End Header -->

  <!-- ======= Modal ======= -->
  <div
    class="modal animate__animated animate__fadeIn"
    :class="showModal ? 'show' : ''"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    :aria-hidden="showModal ? 'false' : 'true'"
    aria-modal="true"
    role="dialog"
    :style="showModal ? 'display:block' : ''"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="showModal = false"
          >
            <img src="@/assets/img/icon-close.svg" alt="" />
          </button>
          <!-- <div class="modal-copy"> -->
          <h3>IMPORTANT INFORMATION</h3>
          <h5>For employees under 25 years of age.</h5>
          <h5 class="pt-3" v-if="clientConfig.content.optIn">
            If you have just started a new job and have less than $6,000 in any
            of your super accounts, you MUST complete an

            <button class="btn">
              <a
                :href="`assets/forms/${clientConfig.content.optIn}`"
                target="_blank"
                >OPT-IN FORM
              </a>
            </button>
            to have insurance.
          </h5>
          <h5 class="pt-3" v-else-if="clientConfig.content.optInLink">
            If you have just started a new job and have less than $6,000 in any
            of your super accounts, you MUST complete an

            <button class="btn">
              <a :href="`${clientConfig.content.optInLink}`" target="_blank"
                >OPT-IN FORM
              </a>
            </button>
            to have insurance.
          </h5>
          <h5 class="pt-3" v-else>
            If you have just started a new job and have less than $6,000 in any
            of your super accounts, you MUST complete an 'opt in form' to have
            insurance.
          </h5>
          <footer>
            <h5 class="pt-3 pb-2">Please contact Gallagher to discuss:</h5>
            <h5>Phone: <a href="tel:1300557782">1300 557 782</a></h5>
            <h5>
              Email:
              <a href="mailto:super-service@ajg.com.au"
                >super-service@ajg.com.au</a
              >
            </h5>
          </footer>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->

  <!-- ======= Hero Section ======= -->
  <section id="hero" class="home" ref="home">
    <div class="overlay">
      <div class="d-flex justify-content-center">
        <div
          class="alertMessage animate__animated animate__slideInDown animate__delay-1s"
        >
          <p>
            <span>Important information</span> for employees
            <b>under 25</b> years of age
          </p>
          <button class="btn" @click="showModal = true">READ&nbsp;MORE</button>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div
            class="col-lg-6 order-1 order-lg-1 hero-img"
            data-aos="zoom-in"
            data-aos-delay="200"
          ></div>
          <div
            class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-2"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h1
              v-if="clientConfig.content.customIntro"
              v-html="clientConfig.content.customIntro"
            ></h1>
            <h1 v-else>Welcome to the Employee Benefits Centre</h1>
            <div class="d-lg-flex">
              <router-link to="/#about" class="btn-get-started scrollto"
                >Get Started</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Hero -->

  <main id="main">
    <!-- ======= About Us Section ======= -->
    <section id="about" class="about">
      <div class="container" data-aos="fade-up">
        <div v-html="clientConfig.content.superFund"></div>
      </div>
    </section>
    <!-- End About Us Section -->

    <!-- ======= Cta Section ======= -->
    <section id="calculators" class="cta" ref="calculators">
      <div class="overlay">
        <div class="mask">
          <div class="container" data-aos="zoom-in">
            <div class="row">
              <div class="col-lg-12">
                <h3>Calculators</h3>
                <p>
                  <strong>Free tools to help you track your money</strong>
                </p>
                <p>
                  Use the following tools to better plan for your retirement.
                </p>
                <div class="modellers-wrapper mt-5">
                  <div
                    class="cta-btn-container white-bg modeller text-center mb-1"
                  >
                    <a
                      href="https://lifetime.gbsau.com/gallagher/supermodeller/"
                      target="_blank"
                    >
                      <img src="@/assets/img/accum-simple.svg" alt="" />
                      <h5>Supermodeller</h5>
                    </a>
                  </div>
                  <!-- <div
                    class="cta-btn-container white-bg modeller text-center mb-1"
                  >
                    <a
                      href="https://lifetime.gbsau.com/gallagher-gbs/small-change/"
                      target="_blank"
                    >
                      <img src="@/assets/img/accum-simple.svg" alt="" />
                      <h5>Small Change Big Savings</h5>
                    </a>
                  </div> -->
                  <!-- <div
                    class="cta-btn-container white-bg modeller text-center mb-1"
                  >
                    <a
                      href="https://lifetime.gbsau.com/gallagher/debt-modeller/"
                      target="_blank"
                    >
                      <img src="@/assets/img/accum-simple.svg" alt="" />
                      <h5>Debt modeller</h5>
                    </a>
                  </div> -->
                  <!-- <div
                    class="cta-btn-container white-bg modeller text-center mb-1"
                  >
                    <a
                      href="https://lifetime.gbsau.dev/lifestyle-v2/gallagher/"
                      target="_blank"
                    >
                      <img src="@/assets/img/accum-simple.svg" alt="" />
                      <h5>Lifestyle modeller</h5>
                    </a>
                  </div> -->
                </div>

                <!-- <a
                  class="cta-btn align-self-center"
                  href="@/src/assets/forms/WesTrac-Super-Apprentice-Preso-Are-You-On-Track-Nov-2021.pdf"
                  >Find out more</a
                > -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Cta Section -->

    <!-- ======= Documents Section ======= -->
    <section id="docs" ref="docs" class="docs section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title mt-5">
          <h2>Documents & Forms</h2>
        </div>

        <div
          class="row"
          data-aos="fade-up"
          data-aos-delay="100"
          v-if="displayDocsFilter"
        >
          <div class="col-lg-12 d-flex justify-content-center">
            <ul id="docs-flters">
              <li
                :class="filterDocs == 'info' ? 'filter-active' : ''"
                @click="filterDocs = 'info'"
              >
                Information
              </li>
              <li
                :class="filterDocs == 'form' ? 'filter-active' : ''"
                @click="filterDocs = 'form'"
              >
                Fact Sheets & Forms
              </li>
            </ul>
          </div>
        </div>

        <div class="row docs-container" data-aos="fade-up" data-aos-delay="200">
          <PDFElement
            v-for="pdf in pdfs"
            :key="pdf"
            :title="pdf.title"
            :description="pdf.description"
            :type="pdf.type"
            :url="pdf.url"
            :externalUrl="pdf.externalUrl"
            v-show="pdf.type == filterDocs"
          />
        </div>
      </div>
    </section>
    <!-- End Docs Section -->

    <!-- ======= Superscore Section ======= -->
    <section
      id="superscore"
      class="cta superscore"
      ref="superscore"
      v-if="clientConfig.superscore == true"
    >
      <div class="overlay">
        <div class="container" data-aos="zoom-in">
          <div class="row">
            <div class="col-lg-6 text-lg-left">
              <h3>Introducing SuperScore<sup>®</sup></h3>
              <p>
                We provide you with a SuperScore, which is an easy way to
                understand the current status of your superannuation account.
                Log into our secure portal,
                <a href="https://www.benefitcentral.com.au/" target="_blank"
                  >Benefit Central</a
                >, to see how SuperScore is calculated and identify
                opportunities to improve your position and potentially your
                retirement and protection outcomes.
              </p>
              <p>
                Access to SuperScore and Benefit Central are available for super
                members who have been part of the super plan for at least 12
                months and have a minimum balance of $6,000. If you have
                recently joined the super plan, please contact Gallagher.
              </p>
            </div>
            <div class="col-lg-6 cta-btn-container white-bg">
              <h3>Start here</h3>
              <ul>
                <li>
                  <i class="ri-check-double-line"></i>
                  Call
                  <strong><a href="tel:1300557782">1300 557 782</a></strong> to
                  discuss SuperScore® with one of our team members below.
                </li>
                <li>
                  <i class="ri-check-double-line"></i>
                  Email
                  <a href="mailto:super-service@ajg.com.au"
                    ><strong>super-service@ajg.com.au</strong></a
                  >
                  or call
                  <strong><a href="tel:1300557782">1300 557 782</a></strong> to
                  get your unique access code
                </li>
              </ul>
              <a
                class="cta-btn"
                :class="clientConfig.settings.ctaWhiteBg ? 'white-bg' : ''"
                href="https://www.benefitcentral.com.au/"
                target="_blank"
                >Login to Benefit Central</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Superscore Section -->

    <!-- ======= Team Section ======= -->
    <section id="team" class="team section-bg page-section">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Your Gallagher Team</h2>
          <p>
            Your dedicated account management team are always ready to help. You
            can reach us in any of the following ways;
          </p>
        </div>

        <div class="row d-flex justify-content-center">
          <TeamMember
            v-for="teamMember in teamMembers"
            :key="teamMember"
            :teamMemberPic="teamMember.teamMemberPic"
            :name="teamMember.name"
            :role="teamMember.role"
            :phoneHref="teamMember.phoneHref"
            :phone="teamMember.phone"
            :email="teamMember.email"
            :social="teamMember.social"
          />
        </div>
      </div>
    </section>
    <!-- End Team Section -->
  </main>
  <!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer" ref="contacts">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-12 footer-contact">
            <h3>Gallagher</h3>
            <h4>Perth</h4>
            <p>
              Level 2, 235 St Georges Terrace
              <br />
              Perth WA 6000<br />
              PO Box 7415<br />
              Cloisters Square WA 6850 <br /><br />
              <strong>Phone: </strong><a href="tel:1300557782">1300 557 782</a
              ><br />
              <strong>Email: </strong>
              <a href="mailto:super-service@ajg.com.au"
                >super-service@ajg.com.au</a
              >
              <br />
            </p>
          </div>

          <div class="col-md-4 col-sm-12 footer-contact extra-top">
            <h4>Sydney</h4>
            <p>
              Level 16, 100 Arthur St
              <br />
              North Sydney NSW 2060<br />
              PO Box 1898<br />
              North Sydney NSW 2060 <br /><br />
            </p>
          </div>

          <div class="col-md-4 col-sm-12 footer-contact extra-top">
            <h4>Brisbane</h4>
            <p>
              Level 15, 144 Edward Street
              <br />
              Brisbane QLD 4000<br />
              GPO Box 248<br />
              Brisbane QLD 4001 <br /><br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container footer-bottom clearfix">
      <div class="copyright">
        &copy; Copyright
        <strong
          ><span>{{ clientConfig.content.footerClientName }}</span></strong
        >. All Rights Reserved<br />
        Gallagher Benefit Services Pty Ltd | ABN 49 611 343 803 | AFSL 488001<br /><br />
        <a target="_blank" href="https://www.ajg.com/au/privacy-policy/"
          >Privacy Policy</a
        >
        <br />
        <a
          target="_blank"
          href="https://www.ajg.com/au/policies-and-codes/benefits-legal-regulatory-information/"
          >Legal and Regulatory Information</a
        >
      </div>
      <div class="credits mr-3">
        Designed by
        <a target="_blank" href="https://www.gallaghercommunications.com.au/"
          >Gallagher Communications</a
        >
      </div>
    </div>
  </footer>
  <!-- End Footer -->

  <a href="#" class="back-to-top"><i class="ri-arrow-up-line"></i></a>
  <!-- <div id="preloader"></div> -->
  <div :class="showModal ? 'modal-backdrop show' : ''"></div>
</template>
