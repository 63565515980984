import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import clientConfig from "/src/config/client.js";
import LifetimeIframeControl from "/src/config/LifetimeIframeControl.js";
import LifetimeHelpers from "/src/config/LifetimeHelpers.js";
import "aos/dist/aos.css";
import VueGtag from "vue-gtag";

import "/src/assets/vendor/bootstrap/css/bootstrap.min.css";
import "/src/assets/vendor/icofont/icofont.min.css";
import "/src/assets/vendor/boxicons/css/boxicons.min.css";
import "/src/assets/vendor/remixicon/remixicon.css";
import "/src/assets/vendor/venobox/venobox.css";
import "/src/assets/vendor/owl.carousel/assets/owl.carousel.min.css";
import "animate.css";

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(LifetimeIframeControl, {
    divId: "app",
    clientConfig: clientConfig,
  })
  .use(LifetimeHelpers, {
    clientConfig: clientConfig,
  })
  .use(VueGtag, {
    appName: "Gallagher Microsites",
    pageTrackerScreenviewEnabled: true,
    config: {
      id: "G-L0VTWZ96CJ",
      cookie_flags: "SameSite=None;Secure",
      params: {
        send_page_view: false,
        debug_mode: false,
      },
    },
  })
  .mount("#app");

// Update the page title to the client's name
document.title = clientConfig.content.general.clientName;
